<template>
    <div>
        <a-row>
            <a-button type="primary" @click="showDrawer({
      id: 0,
      title: '',
      content: '',
      create_time: '',
      type: 2,})">发布公告
            </a-button>
        </a-row>



        <table class="layui-table" lay-size="sm">
            <tr>
                <th>发布时间</th>
                <th>标题</th>
                <th>内容</th>
                <th width="180">是否在BI大屏中显示</th>
                <th width="180">是否在首页显示</th>
                <th width="100">发布人</th>
                <th width="200">操作</th>
            </tr>
            <tr v-for="(item,key) in notice_list">
                <td>{{item.create_time}}</td>
                <td>{{item.title}}</td>
                <td>{{item.content}}</td>
                <td>
                    <a-radio-group v-model="item.is_show_BI" @change="onChange01(item.is_show_BI,item.id)">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="2">否</a-radio>
                    </a-radio-group>
                </td>
                <td>

                    <a-radio-group v-model="item.is_show_home" @change="onChange02(item.is_show_home,item.id)">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="2">否</a-radio>
                    </a-radio-group>
                </td>
                <td>{{item.name}}</td>
                <td>

                    <a-button type="link" 　@click="showDrawer(item,1)" v-if="item.is_recall== 1">重新发布</a-button>
                    <a-button type="link" @click="showDrawer(item,2)" 　v-if="item.is_recall!= 1">编辑</a-button>
                    <a-button type="link" @click="delClick(item)" v-if="item.is_recall== 2" class="redcolor">撤回
                    </a-button>
                    <a-button type="link" v-if="item.is_recall== 1" class="yellow">已撤回</a-button>

                </td>
            </tr>
        </table>
        <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
        <div v-if="edit_info">
            <noticeCreate :edit_time="edit_time" :edit_info="edit_info" :is_republish="is_republish" :close="onCloseDrawer" :visible="drawer_visible"></noticeCreate>
        </div>
    </div>
</template>

<script>
    import noticeCreate from '../../components/drawer/gonggao-create/gonggao-create.vue';

    export default {

        components: {
            noticeCreate,
        },

        data() {
            return {

                edit_time: 0,
                edit_info: false,
                is_republish: '',
                //分页
                page: 1,
                count: 0,
                pagesize: 0,
                // 公告列表变量
                notice_list: [],
                // 定义控制抽屉显示的变量
                drawer_visible: false,

            }
        },


        watch: {
            $route: {
                handler() {
                    //this.page = this.$route.query.page || 1;
                    // this.serial_code = this.$route.query.serial_code || '';
                    // this.name = this.$route.query.name || '';
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.Get_announcement()//w获取公告分页列表
                },
                deep: true
            }
        },

        mounted() {
            //this.page = this.$route.query.page || 1;.
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.Get_announcement()//w获取公告分页列表
        },

        methods: {
            //是否在BI大屏显示
            onChange01(is_show_BI, id) {
                let data = {
                    "id": id,
                    "is_show_BI": is_show_BI
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('set_announcementBI_show'),
                    data: data
                }).then((response) => {
                    console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_announcement()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //是否在首页显示
            onChange02(is_show_home, id) {
                let data = {
                    "id": id,
                    "is_show_home": is_show_home
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('set_announcementhome_show'),
                    data: data
                }).then((response) => {
                    //console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_announcement()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //删除
            delClick(item) {
                console.log('delClick-----------------')
                console.log('url-----------------', this.$api('Set_announcement'))
                this.$sa0.post({
                    url: this.$api('Set_announcement'),
                    data: {
                        id: item.id,
                    }
                }).then((response) => {
                    console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_announcement()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                    }
                })
            },

            //w获取公告分页列表
            Get_announcement() {
                this.$sa0.post({
                    url: this.$api('Get_announcement'),
                    data: {
                        page: this.page
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.notice_list = response.data.result.list;
                            this.count = Number(response.data.result.count);//分页
                            this.pagesize = response.data.result.pagesize;//分页
                            //console.log(this.notice_list)
                            //console.log(JSON.stringify(this.notice_list))
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },

            // 定义 打开抽屉时的 函数 新建编辑公告
            showDrawer(item, is_republish) {
                this.edit_time = new Date() / 1;
                this.edit_info = item;
                this.is_republish = is_republish;
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.Get_announcement()
                this.drawer_visible = false;
            },

        }

    }
</script>

<style scoped>
    .layui-table th{
        font-weight: bold;
        background: #f5f5f5
        }

    .layui-table button{
        margin: 0 5px
        }

    .w-10{
        width: 10%;
        text-align: right;
        }

    table tr{ cursor: pointer}


    .btner{
        width: 100%;
        background: #f9f9f9;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        margin-bottom: 10px;
        }

    .active{
        background: #96cbf9;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #eeeeee;
        height: 26px;
        line-height: 26px;
        padding: 0 10px
        }

    .btnerlist :last-child(1){
        border: none
        }

    .fcadd{
        color: #66c14c !important;
        font-size: 18px !important;
        }

    .btnerlist i{
        color: #999999;
        font-size: 14px;
        vertical-align: bottom;
        }

    .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;

        }

    .btnerlist:hover i, .btnerlist:hover.fcadd{
        color: #ffffff !important
        }

    .isthis{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        }

    .isthis i{
        color: #ffffff
        }

</style>
