<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${info.id===0?'新建':'编辑'}公告`" width="740" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>

                <a-form id="components-form-demo-validate-other" :label-col="{ span: 3, offset: 0 }" :wrapper-col="{ span:12}" v-bind="formItemLayout" @submit="handleSubmit">

                    <a-form-item label="标题">
                        <a-input v-model="info.title"/>
                    </a-form-item>

                    <a-form-item label="内容">
                        <a-textarea rows="8" v-model="info.content"/>
                    </a-form-item>

                    <a-form-item label="发布时间">
                        <a-date-picker v-model="info.create_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                        <!--            <a-date-picker show-time v-model="info.create_time" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>-->
                    </a-form-item>

                    <a-form-item :wrapper-col="{ span: 12, offset: 3 }">
                        <a-button type="primary" @click="doClick()">确定</a-button>
                    </a-form-item>

                </a-form>
            </div>

        </a-drawer>


    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time', 'is_republish'],
        data: () => ({

            info: {
                id: 0,
                title: '',
                content: '',
                create_time: '',
                type: 2,
                is_republish: ''
            },

            do_type: false,

            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },

        }),

        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.edit_info))

                if (this.info.create_time == '') {
                    this.info.create_time = this.formatDateTime(new Date() / 1)
                }
            },
        },
        mounted() {
            this.info = JSON.parse(JSON.stringify(this.edit_info))
            this.info.is_republish = this.is_republish
            if (this.info.create_time == '') {
                this.info.create_time = this.formatDateTime(new Date() / 1)
            }
        },

        methods: {

            //确定
            doClick() {
                let data = {
                    id: this.info.id,
                    title: this.info.title,
                    content: this.info.content,
                    create_time: this.info.create_time,
                    is_republish: this.info.is_republish,
                    type: 2
                }
                //console.log(data)
                this.$sa0.post({
                    url: this.info.id !== 0 ? this.$api('Edit_announcement') : this.$api('Create_announcement'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //显示当前时间
            formatDateTime(inputTime) {
                var date = new Date(inputTime);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
            },


            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },

        }

    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }
</style>
